<template>
  <div class="loader-container" v-if="isLoading">
    <ip-loader />
  </div>
  <div class="user" v-else>
    <div class="user__head">
      <div class="flex-container">
        <div>
          <div class="title">{{ getUserName(user)}}</div>
          <div class="create-task">
            <inline-svg :src="require('../assets/icons/home_24px_outlined.svg')" />
            <a href="#" @click.prevent="showCreateTaskModal(user.phone)">Создать таску</a>
          </div>
        </div>
        <div class="tabs">
          <router-link
            class="tab"
            exact
            :to="{ name: 'user', params: { id: this.user.id } }">
            Личная информация
          </router-link>
          <router-link
            v-if="user.type === 'owner'"
            class="tab"
            exact
            :to="{ name: 'user-objects' }">
            Объекты
          </router-link>
          <router-link
            class="tab"
            exact
            :to="{ name: 'user-reviews' }">
            Отзывы
          </router-link>
          <router-link
            class="tab"
            exact
            :to="{ name: 'user-tasks' }">
            Таски
          </router-link>
          <router-link
            class="tab"
            exact
            :to="{ name: 'user-history' }">
            История
          </router-link>
        </div>
      </div>
    </div>
    <main-info v-if="isMainPage" :user="user" :statistic="statistic"/>
    <router-view v-else>
    </router-view>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import apiRoutes from '../consts/apiRoutes';
import IpLoader from '../components/IpLoader';
import MainInfo from "../components/User/MainInfo";
import CommonMixin from "../mixins/Common.mixin";

export default {
  components: {
    IpLoader,
    MainInfo
  },

  mixins: [ CommonMixin ],

  data() {
    return {
      isLoading: true,
      user: null,
      statistic: null,
      config: {
        toolbar: {
          toolbarViewerLeft: { 
            findbar: false 
          },
          toolbarViewerMiddle: false,
          toolbarViewerRight: false,
          sidebar: false
        },
        secondaryToolbar: false
      }
    };
  },

  async mounted() {
    const response = await this.axios.get(apiRoutes.clinetInfo.replace('{id}', this.$route.params.id));
    this.user = response.data.client;
    this.statistic = response.data.statistic;
    this.isLoading = false;
  },

  computed: {
    isMainPage() {
      return this.$route.name === 'user';
    }
  },

  methods: {
    ...mapMutations('task', [
      'showCreateTaskModal'
    ])
  }
}
</script>

<style scoped lang="scss">
.user{
  display: flex;
  flex-direction: column;
  height: 100%;
  &__head{
    margin-bottom: 10px;
    flex: none;
    border-radius: 4px;
    padding: 16px 24px;
    background: $white;
    box-shadow: 0px 25px 50px -20px rgba($black, 0.1);
    .title{
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: $black;
      margin-bottom: 4px;
    }
    .create-task{
      display: flex;
      align-items: center;
      ::v-deep svg{
        margin-right: 6px;
      }
    }
    .flex-container{
      justify-content: space-between;
      align-items: center;
    }
    .tabs{
      display: inline-flex;
      align-items: center;
      border-radius: 19px;
      background: $athensGray;
      padding: 4px;
      .tab{
        cursor: pointer;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: $black;
        padding: 7px 12px;
        text-decoration: none;
        &.router-link-active{
          background: $scienceBlue;
          color: $white;
          border-radius: 19px;
        }
      }
    }
  }
  &__container{
    display: flex;
    flex-direction: column;
    height: calc(100% - 102px);
  }
}
</style>