<template>
  <div class="loader-container" v-if="isLoading">
    <ip-loader />
  </div>
  <div class="user__container main-info" v-else>
    <div class="user__body">
      <div class="wrapper__body__elem">
        <div class="wrapper__body__elem__info">
          <div class="user-information" v-if="user.type === 'owner'">
            <!-- <div class="user-information__elem">
              <div class="user-information__elem__title">
                24000
              </div>
              <div class="user-information__elem__desc">
                На вашем счету
              </div>
            </div> -->
            <div class="user-information__elem">
              <div class="user-information__elem__title">
                {{ statistic.objectsTotal }}
              </div>
              <div class="user-information__elem__desc">
                Объектов всего
              </div>
            </div>
            <div class="user-information__elem">
              <div class="user-information__elem__title">
                {{ statistic.objectsOccupied }}
              </div>
              <div class="user-information__elem__desc">
                Объектов заселены
              </div>
            </div>
          </div>
          <div class="wrapper__body__elem__info__title">
            Фамилия
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ user.details.lastName || '-' }}
          </div>
          <div class="wrapper__body__elem__info__title">
            Имя
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ user.details.firstName || '-' }}
          </div>
          <div class="wrapper__body__elem__info__title">
            Отчество
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ user.details.middleName || '-' }}
          </div><div class="wrapper__body__elem__info__title">
            Дата рождения 
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ user.details.birthdate || '-' | moment('DD.MM.YYYY') }}
          </div>
          <div class="wrapper__body__elem__info__title">
            Номер паспорта
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ user.details.passportNumber || '-' }}
          </div>
          <div class="wrapper__body__elem__info__title">
            Кем выдан паспорт
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ user.details.passportIssuedBy || '-' }}
          </div>
          <div class="wrapper__body__elem__info__title">
            Когда выдан
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ user.details.passportIssuedDate || '-' | moment('DD.MM.YYYY') }}
          </div>
          <div class="wrapper__body__elem__info__title">
            Данные о регистрации
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ user.details.registrationAddress || '-' }}
          </div>
          <div class="wrapper__body__elem__info__title">
            Идентификационный код
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ user.details.ipn || '-' }}
          </div>
        </div>
      </div>
      <div class="wrapper__body__elem">
        <div class="wrapper__body__elem__title">
          Прикреплённые сканы
        </div>
        <div class="wrapper__body__elem__info">
          <img
            v-for="(document, index) in user.documents"
            :key="index"
            :src="documentBuffer(document)"
            :alt="'document-'+index">
        </div>
      </div>
    </div>
    <div class="user__footer">
      <ip-select
        :options="statuses"
        label="Статус"
        placeholder="Не выбрано"
        @onSelect="selectStatus"
        :selected-item="status"
        v-validate="{required: true}"
        v-model="status"
        name="status"
        :invalid="errors.has('status')"
        open="top"
      >
        <template v-slot:option="slotProps">
          <div class="option">
            <div
              class="status"
              :class="{'inactive': slotProps.option.value !== 'active'}">
            </div>
            <span>
              {{ slotProps.option.text }}
            </span>
          </div>
        </template>
        <template v-slot:selected="slotProps">
          <div class="option">
            <div
              class="status"
              :class="{'inactive': slotProps.selected.value !== 'active'}">
            </div>
            <span>
              {{ slotProps.selected.text }}
            </span>
          </div>
        </template>
      </ip-select>
      <ip-select
        :options="reasons"
        label="Резолюция"
        placeholder="Не выбрано"
        @onSelect="value => reason = value"
        :selected-item="reason"
        v-validate="{required: true}"
        v-model="reason"
        name="reason"
        :invalid="errors.has('reason')"
        open="top"
        :diasbled="!status"
      />
      <ip-text-input
        name="comment"
        id="comment"
        v-model="comment"
        label="Комментарий"
        placeholder="Ввести..."
      />
      <ip-button
        theme="rounded"
        color="blue"
        @click="saveUser">
        Сохранить
      </ip-button>
    </div>
  </div>
</template>

<script>
import IpSelect from "../IpSelect";
import IpButton from "../IpButton";
import IpLoader from "../IpLoader";
import IpTextInput from "../IpTextInput";
import apiRoutes from '../../consts/apiRoutes';

export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    statistic: {
      type: Object,
      default: () => {}
    }
  },

  components: {
    IpSelect,
    IpButton,
    IpLoader,
    IpTextInput
  },

  data() {
    return {
      isLoading: false,
      config: {
        toolbar: {
          toolbarViewerLeft: { 
            findbar: false 
          },
          toolbarViewerMiddle: false,
          toolbarViewerRight: false,
          sidebar: false
        },
        secondaryToolbar: false
      },
      statuses: [
        {
          text: 'активен',
          value: 'active'
        },
        {
          text: 'временно заблокирован',
          value: 'temporary_ban'
        },
        {
          text: 'бан',
          value: 'ban'
        }
      ],
      reasons: [],
      comment: '',
      status: '',
      reason: ''
    };
  },

  mounted() {
    if (this.user.banned) {
      this.status = 'ban';
    } else {
      this.status = 'active';
    }
  },

  methods: {
    selectStatus(status) {
      this.status = status;
      this.reason = '';
      switch (status) {
        case 'active': {
          this.reasons = [
            {
              text: 'без нарушений',
              value: 'без нарушений'
            }
          ];
          this.reason = 'без нарушений';
          break;
        }
        case 'temporary_ban': {
          this.reasons = [
            {
              text: 'не выбрано',
              value: 'not_selected'
            },
            {
              text: 'нецензурные высказывания',
              value: 'нецензурные высказывания'
            },
            {
              text: 'заведомо ложная информация',
              value: 'заведомо ложная информация'
            },
            {
              text: 'агрессия',
              value: 'агрессия'
            },
            {
              text: 'другое',
              value: 'другое'
            }
          ];
          break;
        }
        case 'ban': {
          this.reasons = [
            {
              text: 'не выбрано',
              value: 'not_selected'
            },
            {
              text: 'попытка мошенничества',
              value: 'попытка мошенничества'
            },
            {
              text: 'другое',
              value: 'другое'
            }
          ];
          break;
        }
      }
      
    },

    async saveUser() {
      const isValid = await this.$validator.validate();

      if (!isValid) {
        return;
      }

      const data = {
        clientId: this.user.id,
        ban: this.status !== 'active'
      };

      this.isLoading = true;

      try {
        await this.axios.post(apiRoutes.clientBan, data);
        this.$router.push({ name: 'users' });
      } catch(error) {
        console.warn(error);
        this.isLoading = false;
      }
    },

    documentBuffer(document) {
      const buffer = new Buffer.from(document.buffer, 'base64').toString('base64');
      return `data:image/${document.extension};base64,${buffer}`;
    }
  }
}
</script>

<style scoped lang="scss">
.user__body{
  flex: 1 1 auto;
  height: calc(100% - 92px);
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  img{
    width: 100%;
    margin-bottom: 8px;
  }
}
.user-information{
  padding-top: 16px;
  margin: 0 -5px;
  display: flex;
  margin-bottom: 16px;
  &__elem{
    margin: 5px;
    background: $aquaHaze;
    border-radius: 4px;
    width: 50%;
    padding: 17px 21px;
    &__title{
      font-style: normal;
      font-weight: normal;
      font-size: 48px;
      line-height: 57px;
      text-align: center;
      color: $black;
    }
    &__desc{
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: $black;
    }
  }
}
</style>